<template>
  <div
    class="d-flex align-items-center justify-content-center"
    style="margin-top: 5%">
    <BlockUI :blocked="showLoading">
      <Card>
        <template #content>
          <div>
            Para validação, verificar a correspondência dos dados abaixo com as
            informações do documento apresentado. <br />
            <br />
            Caso os dados informados
            <span class="font-bold">não</span> coincidam com os seus, o
            contracheque em questão não é válido. <br />
            <br />Entre em contato com seu setor de
            <span class="font-bold">Recursos Humanos (RH)</span>.
          </div>
        </template>
      </Card>
      <Card>
        <template #content>
          <div class="p-fluid formgrid grid">
            <div class="field col-12 md:col-5">
              <label for="lotacao">Lotação</label>
              <InputText
                id="lotacao"
                v-model="contrachequeValido.lotacao"
                class="input-text card-desktop"
                disabled />
              <Textarea
                v-model="contrachequeValido.lotacao"
                class="card-mobile input-text"
                rows="3"
                cols="30"
                disabled />
            </div>
            <div class="field col-12 md:col-5">
              <label for="unidadeDeTrabalho">Unidade de Trabalho</label>
              <InputText
                id="unidadeDeTrabalho"
                v-model="contrachequeValido.unidadeDeTrabalho"
                class="input-text card-desktop"
                disabled />
              <Textarea
                v-model="contrachequeValido.unidadeDeTrabalho"
                class="card-mobile input-text"
                rows="3"
                cols="30"
                disabled />
            </div>
            <div class="field col-6 md:col-2">
              <label for="periodo">Periodo</label>
              <InputText
                id="periodo"
                v-model="contrachequeValido.periodo"
                class="input-text"
                disabled />
            </div>
          </div>
          <div class="p-fluid formgrid grid">
            <div class="field col-12 md:col-5">
              <label for="servidor">Servidor</label>
              <InputText
                id="servidor"
                v-model="contrachequeValido.servidor"
                class="input-text card-desktop"
                disabled />
              <Textarea
                v-model="contrachequeValido.servidor"
                class="card-mobile input-text"
                rows="3"
                cols="30"
                disabled />
            </div>
            <div class="field col-6 md:col-3">
              <label for="matricula">Matricula</label>
              <InputText
                id="matricula"
                v-model="contrachequeValido.matricula"
                class="input-text"
                disabled />
            </div>
            <div class="field col-6 md:col-4">
              <label for="cpf">CPF</label>
              <InputText
                id="cpf"
                v-model="contrachequeValido.cpf"
                class="input-text"
                disabled />
            </div>
          </div>
          <div class="p-fluid formgrid grid">
            <div class="field col-12 md:col-5">
              <label for="cargo">Cargo</label>
              <InputText
                id="cargo"
                v-model="contrachequeValido.cargo"
                class="input-text"
                disabled />
            </div>
            <div class="field col-6 md:col-3">
              <label for="unidadePagadora">Unidade Pagadora</label>
              <InputText
                id="unidadePagadora"
                v-model="contrachequeValido.unidadePagadora"
                class="input-text"
                disabled />
            </div>
            <div class="field col-6 md:col-4">
              <label for="regime">Regime</label>
              <InputText
                id="regime"
                v-model="contrachequeValido.regime"
                class="input-text"
                disabled />
            </div>
          </div>
          <div class="p-fluid formgrid grid">
            <div class="field col-6 md:col">
              <label for="tempoEstado">T.S. Estado</label>
              <InputText
                id="tempoEstado"
                v-model="contrachequeValido.tempoEstado"
                class="input-text"
                disabled />
            </div>
            <div class="field col-6 md:col">
              <label for="tempoQuinquenio">T.S. Quinquênio</label>
              <InputText
                id="tempoQuinquenio"
                v-model="contrachequeValido.tempoQuinquenio"
                class="input-text"
                disabled />
            </div>
            <div class="field col-6 md:col">
              <label for="tempoAposentadoria">T.S. Aposentadoria</label>
              <InputText
                id="tempoAposentadoria"
                v-model="contrachequeValido.tempoAposentadoria"
                class="input-text"
                disabled />
            </div>
            <div class="field col-6 md:col">
              <label for="tempoAdquirido">T.S. Adquirido</label>
              <InputText
                id="tempoAdquirido"
                v-model="contrachequeValido.tempoAdquirido"
                class="input-text"
                disabled />
            </div>
            <div class="field col-12 md:col">
              <label for="classeFuncional">CLF</label>
              <InputText
                id="classeFuncional"
                v-model="contrachequeValido.classeFuncional"
                class="input-text"
                disabled />
            </div>
            <div class="field col-12 md:cl-5">
              <label for="codigoCertificador">Código Certificador</label>
              <InputText
                id="codigoCertificador"
                v-model="contrachequeValido.codigoCertificador"
                class="input-text"
                disabled />
            </div>
          </div>

          <div class="p-fluid formgrid grid">
            <div class="field col md:col">
              <label for="bruto">Bruto R$</label>
              <InputText
                id="bruto"
                v-model="contrachequeValido.bruto"
                class="input-text"
                disabled />
            </div>
            <div class="field col md:col">
              <label for="liquido">Líquido R$</label>
              <InputText
                id="liquido"
                v-model="contrachequeValido.liquido"
                class="input-text"
                disabled />
            </div>
          </div>
        </template>
      </Card>
    </BlockUI>
  </div>
</template>
<script>
import ContrachequeService from '@/service/ContrachequeService'
export default {
  props: ['codigo', 'matricula', 'bruto'],

  data() {
    return {
      showLoading: false,
      params: new Map(),
      contrachequeValido: {},
    }
  },

  created() {
    this.contrachequeService = new ContrachequeService(this.$http)
    this.showLoading = true
  },

  mounted() {
    this.carregarContracheque()
  },

  methods: {
    carregarContracheque() {
      this.params.set('codigo', this.codigo)
      this.params.set('matricula', this.matricula)
      this.params.set('bruto', this.bruto)

      this.contrachequeService
        .validarContracheque(this.params)
        .then((res) => {
          this.contrachequeValido = res
          this.showLoading = false
          this.tratarBrutoEDescontos()
        })
        .catch((err) => {
          this.showToastError(err)
        })
    },

    tratarBrutoEDescontos() {
      this.contrachequeValido.liquido = this.formatarValoresDeLancamentos(
        Number(
          (this.contrachequeValido.bruto - this.contrachequeValido.descontos) /
            100,
        ).toFixed(2),
      )
      this.contrachequeValido.bruto = this.formatarValoresDeLancamentos(
        Number(this.contrachequeValido.bruto / 100).toFixed(2),
      )
    },

    formatarValoresDeLancamentos(valor) {
      const value = parseFloat(valor.replace(',', '.'))
      if (!isNaN(value)) {
        const formattedValue = value.toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
        return 'R$ ' + formattedValue
      }
      return 'R$ ' + valor
    },

    showToastError(err) {
      const mensagem = err.response
        ? err.response.data.message
        : 'Erro de conexão com a aplicação.'
      this.$toast.add({
        severity: 'error',
        summary: mensagem,
        life: 10000,
      })
    },
  },
}
</script>
<style scoped>
.input-text {
  width: 100%;
  color: black;
}
</style>
